<template>
  <div>
    <div
      class="icone-chat"
      @click="alternarChat(chatAberto)"
      :class="{ digitando: carregandoResposta }"
    >
      <img
        :src="logoIAPadraoBranco"
        class="icone-robo"
        alt="icone assistente outbox"
        ref="iconeRobo"
      />
    </div>
    <transition name="chat" @before-enter="beforeEnter" @enter="enter" @leave="leave">
      <div v-if="chatAberto" class="janela-chat" ref="chatWindow">
        <div class="titulo-chat">
          <div class="titulo-box">
            <span class="fundo-logo-ia">
              <img
                v-for="imagem in imagens"
                :src="imagem.src"
                :key="imagem.key"
                :class="{
                  ativo: imagemAtual === imagem.key,
                  'robo-gif': !imagem.key.includes('Padrao'),
                }"
                class="logo-ia"
              />
            </span>

            <span
              class="titulo"
              @mousedown="startDrag"
              @mousemove="whileDragging"
              @mouseup="stopDrag"
              @mouseleave="stopDrag"
              ref="chatWindowDrag"
            >
              <span class="texto-nome"> Singú </span>
              <span class="texto-desc"> I.A. → Assistente Pessoal </span>
            </span>
          </div>
          <span class="icones-box">
            <span class="material-symbols-outlined icone-info" id="icone-info">
              chat_info
            </span>
            <b-tooltip
              target="icone-info"
              triggers="hover"
              placement="top"
              variant="secondary"
              class="tooltipResults"
              boundary="window"
            >
              IA Outbox: Assistente pessoal para consultas <br />
              (versão Beta)
            </b-tooltip>
            <span
              class="material-symbols-outlined icone-fechar"
              @click="chatAberto = false"
            >
              close
            </span>
          </span>
        </div>
        <div
          class="corpo-chat"
          ref="chatContainer"
          @scroll="monitoraScrollUsuario"
          @wheel="monitoraScrollUsuario"
        >
          <div
            v-for="(mensagem, indice) in historicoChatSuperbox"
            :key="indice"
            :style="{
              'justify-content':
                mensagem.enviado === true
                  ? 'flex-end'
                  : mensagem.enviado === false
                  ? 'flex-start'
                  : 'center',
            }"
            style="display: flex"
          >
            <div
              v-if="mensagem.divisor"
              style="display: flex; width: 100%; justify-content: center"
            >
              <div
                v-if="mensagem.divisor"
                v-html="mensagem.texto"
                class="mensagem-divisor"
              ></div>
            </div>
            <div
              v-else
              :class="{
                'mensagem-chat': true,
                enviado: mensagem.enviado,
                recebido: !mensagem.enviado,
              }"
            >
              <div v-html="mensagem.texto"></div>
              <div class="hora-msg">{{ mensagem.hora }}</div>
            </div>
          </div>
          <template v-if="!termosUsoAssistente">
            <div class="overlay">
              <div class="texto-termo">
                Usuário concorda com os
                <a :href="linkTermos" target="_blank">
                  <strong> Termos de Uso </strong>
                </a>
                do nosso serviço de chat, incluindo o uso de seus dados para melhorar a
                experiência de atendimento e a conformidade com nossas políticas de
                privacidade.
              </div>
              <div class="check-termo">
                <input
                  type="checkbox"
                  v-model="checkTermosUsoAssistente"
                  class="checkbox"
                />
                <span class="texto-check">Estou de acordo</span>
              </div>
              <div class="botoes-box">
                <button class="botao-termos declinar" @click="chatAberto = false">
                  Declinar
                </button>
                <button
                  class="botao-termos prosseguir"
                  :class="{
                    disabled: !checkTermosUsoAssistente,
                  }"
                  @click="prosseguirChat()"
                >
                  Prosseguir
                </button>
              </div>
            </div>
          </template>
        </div>

        <template>
          <div class="chat-container">
            <textarea
              v-model="entradaChat"
              placeholder="Mensagem..."
              @keydown="verificarEnvio"
              class="entrada-chat"
              maxlength="300"
              @input="checkTransicao"
            >
            </textarea>
            <div class="contador">{{ entradaChat.length }}/300</div>
            <span
              v-if="!carregandoResposta"
              class="material-symbols-outlined botao-enviar"
              @click="enviarChat()"
              :class="{
                disabled: entradaChat == '' || carregandoResposta || !termosUsoAssistente,
              }"
            >
              send
            </span>
            <span
              v-else
              class="material-symbols-outlined botao-enviar"
              @click="stopSimulacao()"
            >
              stop_circle
            </span>
            <div class="chat-aviso">
              Singú está sujeito a falhas. Sempre confira as informações.
            </div>
          </div>
        </template>
      </div>
    </transition>
  </div>
</template>

<script>
import { chatOutboxStore } from "@/stores/chat-Outbox.js";
import { userPreferenceStore } from "@/stores/userPreference";
import { mapWritableState, mapActions } from "pinia";

export default {
  name: "ChatOutbox",
  data() {
    return {
      dragging: false,
      posX: 0,
      posY: 0,
      stopDragFromDocument: null,

      logoIAPadrao: "/img/assistente-chat/Assistente-Outbox-padrao.png",
      logoIAPadraoBranco: "/img/assistente-chat/Assistente-Outbox-padrao-branco.png",

      fundoChat: "/img/assistente-chat/Assistente-Outbox-fundo-chat.png",

      imagens: [
        {
          key: "logoIAPadrao",
          src: "/img/assistente-chat/Assistente-Outbox-padrao-floating.gif",
          imagem: true,
        },
        {
          key: "logoRoboParaPonto",
          src: "/img/assistente-chat/Assistente-Outbox-RoboParaPontos.gif",
          imagem: true,
        },
        {
          key: "logoDigitando",
          src: "/img/assistente-chat/Assistente-Outbox-Pontos.gif",
          imagem: true,
        },
        {
          key: "logoPontoParaRobo",
          src: "/img/assistente-chat/Assistente-Outbox-PontoParaRobo.gif",
          imagem: true,
        },
      ],

      imagemAtual: "logoIAPadrao",
      timerId: null,

      dataFormatada: "",
      horaFormatada: "",

      carregandoResposta: false,
      contextoAtual: {
        codigo_agrupador: "",
        rota: "/inicio",
        nome: "",
        descricao: "",
      },

      linkTermos: "/docs/Termos de serviço - Assistente Outbox.pdf",

      textoCompletoSimulado: "",
      idIntervaloSimulacao: 0,

      checkTermosUsoAssistente: false,

      listaAvisosInvalido: [
        "Desculpe, não consegui entender sua pergunta. Poderia reformulá-la?",
        "Ops, não consegui captar o que você quis dizer. Poderia explicar de outra forma?",
        "Parece que não entendi sua pergunta. Você poderia reformular, por favor?",
        "Hmm, não consegui processar sua pergunta corretamente. Tente me dizer de outra maneira.",
        "Desculpe, não entendi o que você quis dizer. Poderia ser um pouco mais claro?",
        "Não consegui compreender sua pergunta. Você poderia ser mais específico?",
        "Acho que não captei sua questão. Você pode tentar novamente, por favor?",
        "Poxa, parece que não entendi. Você pode reescrever sua pergunta?",
        "Minhas desculpas, mas não consegui entender sua pergunta. Você poderia tentar de novo?",
        "Uh oh, acho que me confundi. Pode reformular sua pergunta?",
        "Não entendi bem isso. Você se importa de explicar de outra forma?",
      ],

      listaAvisosContexto: [
        "Oops... Ainda estou aprendendo! Hoje só posso te responder se você estiver em alguma das páginas de consultas Superbox.",
        "Ops, ainda estou em treinamento! Por enquanto, só consigo responder perguntas nas páginas de consultas Superbox.",
        "Desculpe, ainda estou aprendendo. Neste momento, só posso ajudar nas páginas de consultas Superbox.",
        "Parece que estou um pouco limitado agora. Só posso responder dentro das páginas de consultas Superbox.",
        "Estou em fase de aprendizado e, por ora, só posso te ajudar nas páginas de consultas Superbox.",
        "Por enquanto, estou equipado para responder apenas nas páginas de consultas Superbox.",
        "Ainda estou em fase de treinamento. Me encontre nas páginas de consultas Superbox para te ajudar!",
        "Estou aprendendo mais a cada dia! No momento, só respondo nas páginas de consultas Superbox.",
        "Oops, minhas habilidades estão em desenvolvimento. Por enquanto, só posso responder em consultas Superbox.",
        "Ainda estou crescendo em conhecimento. Visite as páginas de consultas Superbox para obter respostas!",
        "Minha programação atual me permite responder apenas nas páginas de consultas Superbox. Nos vemos lá!",
      ],

      listaAvisosInicio: [
        "Bem-vindo, meu nome é Singú, sou seu Assistente Outbox, como posso ajudar?",
        "Olá! Bem-vindo ao Assistente Outbox. Como posso te ajudar hoje?",
        "Bem-vindo ao Assistente Outbox! Eu sou Singú e estou aqui para ajudar. O que você gostaria de saber?",
        "Seja bem-vindo ao nosso chat, sou Singú, seu Assistente Outbox. Como posso ser útil?",
        "Olá! Como posso ajudar você hoje?",
        "Oi! Posso ajudar em algo? Estou aqui para isso!",
        "Bem-vindo ao Assistente Outbox! Tem alguma dúvida que posso esclarecer?",
        "Olá, que bom ver você aqui no Assistente Outbox! Como posso ajudar?",
        "Seja bem-vindo ao Assistente Outbox. O que você precisa hoje?",
        "Olá, sou o Singú, seu Assistente Outbox, como posso te ajudar hoje?",
        "Olá! Singú pronto para te ajudar. Qual é a sua pergunta?",
      ],

      rolagemAutomaticaHabilitada: true,

    };
  },

  watch: {
    $route: function (to, from) {
      if (this.chatAberto) {
        this.chatAberto = false;
      }
    },

    abrirChat() {
      if (this.abrirChat) {
        this.alternarChat(false);
        this.abrirChat = false;
      }
    },
  },

  methods: {
    checkTransicao() {
      clearTimeout(this.timerId);

      if (this.entradaChat.length > 0 && this.imagemAtual !== "logoDigitando") {
        this.imagemAtual = "logoRoboParaPonto";
        this.timerId = setTimeout(() => {
          this.imagemAtual = "logoDigitando";
        }, 1000);
      } else if (this.entradaChat.length === 0 && this.imagemAtual !== "logoIAPadrao") {
        this.imagemAtual = "logoPontoParaRobo";
        this.timerId = setTimeout(() => {
          this.imagemAtual = "logoIAPadrao";
        }, 1000);
      }
    },

    async alternarChat(estadoAtual) {
      if (!estadoAtual) {
        this.abrirJanelaChat();
        await this.varificaPreferenciaTermos();
        this.atualizarContexto(this.contexto);
      } else {
        this.fecharJanelaChat();
      }

      if (this.chatAberto && this.termosUsoAssistente) {
        this.verificarContexto();
      }
    },

    async prosseguirChat() {
      const nomePrefTermosDeUso = "PortalOutbox_TermosDeUsoAssistente";
      await this.substituiPreferencia(nomePrefTermosDeUso, this.checkTermosUsoAssistente);
      this.termosUsoAssistente = this.checkTermosUsoAssistente;

      this.verificarContexto();
    },

    verificarContexto() {
      const ultimoContexto =
        this.historicoChatSuperbox.length > 0
          ? this.historicoChatSuperbox[this.historicoChatSuperbox.length - 1].contexto
          : null;

      if (this.historicoChatSuperbox.length > 0 && ultimoContexto) {
        const rotaMudou = ultimoContexto.rota !== this.contextoAtual.rota;
        const codigoMudou =
          ultimoContexto.codigo_agrupador !== this.contextoAtual.codigo_agrupador &&
          this.contextoAtual.codigo_agrupador !== "";

        if (rotaMudou && (codigoMudou || this.contextoAtual.codigo_agrupador === "")) {
          this.adicionarDivisorDeContexto();
        }
      }

      if (this.historicoChatSuperbox.length === 0) {
        this.adicionarMensagemBoasVindas();
      }

      this.$nextTick(() => {
        this.scrollParaBaixo();
      });
    },

    async adicionarMensagemBoasVindas() {
      this.carregandoResposta = true;

      this.atualizaHora();

      const mensagemBoasVindas = this.getFraseAleatoriaInicio();
      await this.simularDigitacao(mensagemBoasVindas);
    },

    getFraseAleatoriaInicio() {
      const indiceAleatorio = Math.floor(Math.random() * this.listaAvisosInicio.length);
      return this.listaAvisosInicio[indiceAleatorio];
    },

    verificarEnvio(event) {
      if (event.key === "Enter" && !event.shiftKey) {
        event.preventDefault();
        this.enviarChat();
      }
    },

    atualizaHora() {
      const agora = new Date();
      this.dataFormatada = "";
      this.dataFormatada = agora.toLocaleDateString("pt-BR");
      this.horaFormatada = "";
      this.horaFormatada = agora.toLocaleTimeString("pt-BR", {
        hour: "2-digit",
        minute: "2-digit",
      });
    },

    async enviarChat() {
      this.rolagemAutomaticaHabilitada = true;

      if (
        !this.carregandoResposta &&
        this.entradaChat != "" &&
        this.termosUsoAssistente
      ) {
        this.carregandoResposta = true;
        const pergunta = this.entradaChat.replace(/\n/g, "<br>");
        this.atualizaHora();

        this.historicoChatSuperbox.push({
          data: this.dataFormatada,
          hora: this.horaFormatada,
          texto: await this.processarMarkdown(pergunta),
          enviado: true,
          contexto: this.contexto,
        });
        this.entradaChat = "";

        this.$nextTick(() => {
          this.scrollParaBaixo();
        });

        await this.obterResposta(pergunta);
      }
    },

    adicionarDivisorDeContexto() {
      let contextoTexto = this.contextoAtual.descricao;
      if (this.contextoAtual.codigo_agrupador) {
        contextoTexto +=
          " <br/> Código agrupador: " + this.contextoAtual.codigo_agrupador;
      }
      this.historicoChatSuperbox.push({
        texto: contextoTexto,
        divisor: true,
        contexto: this.contextoAtual,
      });
    },

    atualizarContexto(novoContexto) {
      this.contextoAtual = novoContexto;
    },

    async obterResposta(pergunta) {
      if (this.contexto.codigo_agrupador && this.contexto.codigo_agrupador != "") {
        const letras = pergunta.match(/[a-zA-Z]/g);
        if (letras && letras.length >= 2) {
          await this.requisicaoChatSuperbox(
            this.contexto.codigo_agrupador,
            pergunta,
            this.thread_id
          );

          if (this.resultadoRequisição.resposta) {
            var respostaMarkdown = this.resultadoRequisição.resposta;
          } else if (typeof this.resultadoRequisição == "string") {
            var respostaMarkdown = this.resultadoRequisição;
          } else {
            var respostaMarkdown = "Assistente indisponível.";
          }

          if (this.resultadoRequisição.thread_id) {
            this.thread_id = this.resultadoRequisição.thread_id;
          }

          const respostaProcessada = this.processarMarkdownEQuebras(respostaMarkdown);
          await this.simularDigitacao(respostaProcessada);
          this.$nextTick(() => this.scrollParaBaixo());
        } else {
          const textoPermissao = this.getFraseAleatoriaInvalido();
          await this.simularDigitacao(textoPermissao);
          this.$nextTick(() => this.scrollParaBaixo());
        }
      } else {
        const textoPermissao = this.getFraseAleatoriaContexto();
        await this.simularDigitacao(textoPermissao);
        this.$nextTick(() => this.scrollParaBaixo());
      }
    },

    getFraseAleatoriaInvalido() {
      const indiceAleatorio = Math.floor(Math.random() * this.listaAvisosInvalido.length);
      return this.listaAvisosInvalido[indiceAleatorio];
    },

    getFraseAleatoriaContexto() {
      const indiceAleatorio = Math.floor(Math.random() * this.listaAvisosContexto.length);
      return this.listaAvisosContexto[indiceAleatorio];
    },

    processarMarkdownEQuebras(texto) {
      texto = this.processarMarkdown(texto);
      return this.formatarQuebrasDeLinha(texto);
    },

    processarMarkdown(texto) {
      const negritoRegex = /\*\*(.*?)\*\*/g;
      const italicoRegex = /\*(.*?)\*/g;

      texto = texto.replace(negritoRegex, "<strong>$1</strong>");
      texto = texto.replace(italicoRegex, "<em>$1</em>");

      return texto;
    },

    formatarQuebrasDeLinha(texto) {
      return texto.replace(/\n/g, "<br>");
    },

    async simularDigitacao(texto) {
      let i = 0;
      const velocidade = 30;
      this.textoCompletoSimulado = texto;

      this.historicoChatSuperbox.push({
        data: this.dataFormatada,
        hora: this.horaFormatada,
        texto: "",
        enviado: false,
        contexto: this.contexto,
        simulação: true,
      });

      return new Promise((resolve) => {
        this.idIntervaloSimulacao = setInterval(() => {
          if (i < texto.length) {
            let textoParcial = texto.substring(0, i + 1);

            if (texto[i] === "<") {
              const closeTagIndex = texto.indexOf(">", i);
              if (closeTagIndex !== -1) {
                textoParcial = texto.substring(0, closeTagIndex + 1);
                i = closeTagIndex;
              }
            }

            this.atualizarChat(textoParcial, true);
            i++;
          } else {
            clearInterval(this.idIntervaloSimulacao);
            this.idIntervaloSimulacao = null;
            resolve();

            this.$nextTick(() => this.scrollParaBaixo());
            this.carregandoResposta = false;
            this.checkTransicao();
          }
        }, velocidade);
      });
    },

    stopSimulacao() {
      if (this.idIntervaloSimulacao) {
        clearInterval(this.idIntervaloSimulacao);
        this.idIntervaloSimulacao = null;
        const ultimoTexto = this.historicoChatSuperbox.pop();
        this.historicoChatSuperbox.push({
          ...ultimoTexto,
          texto: this.textoCompletoSimulado,
          simulação: false,
        });
        this.$nextTick(() => this.scrollParaBaixo());
        this.carregandoResposta = false;
      }
    },

    atualizarChat(texto, isSimulating = false) {
      if (isSimulating) {
        this.historicoChatSuperbox.pop();
      }

      this.historicoChatSuperbox.push({
        data: this.dataFormatada,
        hora: this.horaFormatada,
        texto: texto,
        enviado: false,
        contexto: this.contexto,
      });

      this.$nextTick(() => this.scrollParaBaixo());
    },

    aplicarFormato(texto, tipo) {
      if (tipo === "negrito") {
        return `<strong>${texto}</strong>`;
      } else if (tipo === "italico") {
        return `<em>${texto}</em>`;
      }
      return texto;
    },

    scrollParaBaixo() {
      const chatContainer = this.$refs.chatContainer;
      if (chatContainer && this.rolagemAutomaticaHabilitada) {
        chatContainer.scrollTop = chatContainer.scrollHeight;
      }
    },

    monitoraScrollUsuario(event) {
      let chatContainer = this.$refs.chatContainer;
      const estaNoFundo =
        chatContainer.scrollHeight - chatContainer.scrollTop - 20 <
        chatContainer.clientHeight;

      this.rolagemAutomaticaHabilitada = estaNoFundo;
    },

    startDrag(event) {
      this.dragging = true;
      this.posX = event.clientX;
      this.posY = event.clientY;

      document.addEventListener("mousemove", this.whileDragging);
      document.addEventListener("mouseup", this.stopDrag);
      document.addEventListener("mouseleave", this.stopDragFromDocument);
    },

    whileDragging(event) {
      if (this.dragging) {
        let deltaX = event.clientX - this.posX;
        let deltaY = event.clientY - this.posY;

        let chatWindow = this.$refs.chatWindow;
        let rect = chatWindow.getBoundingClientRect();

        let newRight = parseInt(window.getComputedStyle(chatWindow).right, 10) - deltaX;
        let newBottom = parseInt(window.getComputedStyle(chatWindow).bottom, 10) - deltaY;

        newRight = Math.min(Math.max(newRight, 0), window.innerWidth - rect.width - 250);

        newBottom = Math.min(
          Math.max(newBottom, -55),
          window.innerHeight - rect.height - 50
        );

        chatWindow.style.right = `${newRight}px`;
        chatWindow.style.bottom = `${newBottom}px`;

        this.posX = event.clientX;
        this.posY = event.clientY;
      }
    },

    stopDrag() {
      this.dragging = false;

      document.removeEventListener("mousemove", this.whileDragging);
      document.removeEventListener("mouseup", this.stopDrag);
    },

    beforeEnter(el) {
      el.style.opacity = 0;
      el.style.transform = "translate(100%, 50%) scale(0)";
    },
    enter(el, done) {
      el.offsetHeight;
      el.style.transition = "opacity 0.4s ease-out, transform 0.4s ease-out";
      el.style.opacity = 1;
      el.style.transform = "translate(0%, -10%) scale(1)";
      done();
    },
    leave(el, done) {
      el.style.transition = "opacity 0.4s ease-in, transform 0.4s ease-in";
      el.style.opacity = 0;
      el.style.transform = "translate(150%, 70%) scale(0)";
      setTimeout(done, 500);
    },

    iniciarPulsacao() {
      const iconeRobo = this.$refs.iconeRobo;
      const reiniciarAnimacao = () => {
        iconeRobo.style.animation = "none";
        setTimeout(() => {
          iconeRobo.style.animation = "";
        }, 10);
      };

      setInterval(reiniciarAnimacao, 30000);
    },

    preloadImages(imageSources) {
      imageSources.forEach((src) => {
        const img = new Image();
        img.src = src;
      });
    },

    async varificaPreferenciaTermos() {
      const nomePrefTermosDeUso = "PortalOutbox_TermosDeUsoAssistente";
      const preferenciasFavoritos = await this.syncPreferenciaGeral(nomePrefTermosDeUso);
      if (preferenciasFavoritos === undefined) {
        await this.createPreferencia(nomePrefTermosDeUso);
        await this.substituiPreferencia(nomePrefTermosDeUso, false);
      } else {
        if (preferenciasFavoritos.Valor && preferenciasFavoritos.Valor != "") {
          this.termosUsoAssistente = preferenciasFavoritos.Valor == "true";
        }
      }
    },

    atualizaPreferencia() {},

    ...mapActions(chatOutboxStore, [
      "resetStoreChat",
      "requisicaoChatSuperbox",
      "abrirJanelaChat",
      "fecharJanelaChat",
    ]),

    ...mapActions(userPreferenceStore, [
      "syncPreferencias",
      "createPreferencia",
      "substituiPreferencia",
      "syncPreferenciaGeral",
    ]),
  },

  computed: {
    digitando() {
      if (this.entradaChat.length > 0) {
        return true;
      } else {
        return false;
      }
    },
    ...mapWritableState(chatOutboxStore, {
      chatAberto: "chatAberto",
      abrirChat: "abrirChat",
      entradaChat: "entradaChat",
      historicoChatSuperbox: "historicoChatSuperbox",
      contexto: "contexto",
      resultadoRequisição: "resultadoRequisição",
      thread_id: "thread_id",
    }),

    ...mapWritableState(userPreferenceStore, {
      usuarioLogado: "usuarioLogado",
      termosUsoAssistente: "termosUsoAssistente",
    }),
  },

  async mounted() {
    this.resetStoreChat();
    this.preloadImages(this.imagens.map((imagem) => imagem.src));

    setTimeout(() => {
      this.iniciarPulsacao();
    }, 10000);
  },
};
</script>

<style lang="scss" scoped>
.icone-chat {
  position: fixed;
  bottom: 10px;
  right: 15px;
  background-color: #0389dd;
  // background-color: white;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  padding: 5px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  z-index: 999;
  color: white;
}

.icone-robo {
  width: 30px;
  height: 30px;
  font-weight: 300;
  font-size: 26px;
  display: inline-block;
  animation: pulsar 3s ease-in-out infinite;
  animation-delay: 27s;
  transition: 0.5s;
  transform: scale(1.1);

  &:hover {
    transform: scale(1.2);
  }
}

@keyframes pulsar {
  0%,
  100% {
    transform: scale(1);
  }
  25%,
  75% {
    transform: scale(1.1);
  }
}

.janela-chat {
  position: fixed;
  bottom: 0px;
  right: 10%;
  width: 350px;
  border-radius: 15px;
  overflow: hidden;
  padding: 0;
  display: flex;
  flex-direction: column;
  z-index: 999;
  box-shadow: 0 0 45px rgba(0, 0, 0, 0.65);
  background-image: url(/img/assistente-chat/Assistente-Outbox-fundo-chat.png);
  background-repeat: repeat;
  background-size: cover;
}

.titulo-chat {
  cursor: default;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: black;
  padding: 0 10px;
  background-color: white;
  border-radius: 10px;
  height: 70px;
  user-select: none;
}

.titulo-box {
  display: flex;
  width: 100%;
  height: 52px;
}

.titulo {
  cursor: grab;
  display: flex;
  flex-direction: column;
  justify-content: center;
  line-height: 21px;
  margin: 0 0 0 10px;

  &:active {
    cursor: grabbing;
  }
}

.texto-nome {
  color: #2b3769;
  font-size: 18px;
  font-weight: 500;
}

.texto-desc {
  font-size: 14px;
  font-weight: 300;
  color: #a1a1a1;
}

.fundo-logo-ia {
  width: 55px;
  height: 55px;
  border-radius: 50%;
  border: 2px solid #007bff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo-ia {
  width: 37px;
  height: 37px;
}

.fundo-logo-ia img {
  transition: opacity 0.3s ease-in-out;
  opacity: 0;
  position: absolute;
}

.fundo-logo-ia img.ativo {
  opacity: 1;
  position: absolute;
}

.robo-gif {
  // transform: scaleX(0.95);
  width: 37px;
  height: 37px;
  // top: 19px;
}

.icones-box {
  display: flex;
  gap: 20px;
  cursor: default;
  align-items: center;
}

.icone-info {
  cursor: default;
  color: #007bff;

  opacity: 0.5;

  &:hover {
    opacity: 0.7;
  }
}

.icone-fechar {
  cursor: pointer;
  font-size: 28px;
  opacity: 0.3;

  &:hover {
    opacity: 0.5;
  }
}

.corpo-chat {
  height: 450px;
  max-height: 450px;
  overflow-y: auto;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
}

.mensagem-divisor {
  display: flex;
  align-items: center;
  text-align: center;
  font-style: italic;
  color: #888;
  font-size: 12px;
  font-weight: 300;
  line-height: 14px;
  width: 100%;
  background-color: rgb(252, 252, 252);
  border-radius: 15px;
  padding: 5px;
  margin: 10px 5px;
}

.mensagem-divisor:before,
.mensagem-divisor:after {
  content: "";
  flex: 1;
  border-bottom: 1px solid #ccc;
}

.mensagem-divisor:before {
  margin-right: 10px;
}

.mensagem-divisor:after {
  margin-left: 10px;
}

.mensagem-chat {
  position: relative;
  margin: 10px;
  background-color: #6d6d6d;
  border-radius: 10px;
  max-width: 85%;
  padding: 10px;
  font-size: 14px;
  font-weight: 300;
  line-height: 18px;
  cursor: default;
  word-break: break-word;
  width: fit-content;
}

.enviado {
  background-color: #703eff;
  color: white;
  text-align: right;
  align-self: flex-end;
}

.recebido {
  background-color: rgb(251, 251, 251);
  color: black;
  text-align: left;
  align-self: flex-start;
}

.enviado::after {
  content: "";
  position: absolute;
  bottom: 4px;
  right: -5px;
  width: 0;
  height: 0;
  border: 10px solid transparent;
  border-left-color: #703eff;
  transform: rotate(-90deg) scale(1.4);
  border-radius: 20%;
}

.recebido::after {
  content: "";
  position: absolute;
  bottom: 4px;
  left: -5px;
  width: 0;
  height: 0;
  border: 10px solid transparent;
  border-right-color: rgb(251, 251, 251);
  transform: rotate(90deg) scale(1.4);
  border-radius: 20%;
}

.hora-msg {
  font-size: 12px;
  font-weight: 400;
  width: 100%;
  text-align: right;
}

.chat-container {
  position: relative;
  width: 100%;
  background-color: white;
  border-radius: 10px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.entrada-chat {
  width: 100%;
  border: none;
  background-color: #e9e9e9;
  color: black;
  height: 50px;
  border-radius: 30px;
  max-height: 80px;
  min-height: 50px;
  font-size: 14px;
  font-weight: 300;
  position: relative;
  top: 5px;
  padding: 15px 77px 15px 20px;
  line-height: 16px;
}

.contador {
  position: absolute;
  right: 45px;
  bottom: 43px;
  background-color: transparent;
  color: #666;
  font-size: 12px;
  line-height: 16px;
}

.botao-enviar {
  position: absolute;
  right: 15px;
  bottom: 43px;
  background-color: transparent;
  color: #0389dd;
  font-size: 26px;
  cursor: pointer;
  transition: 0.3s;
  opacity: 0.5;
  line-height: 16px;

  &:hover {
    opacity: 0.7;
  }
}

.chat-aviso {
  font-size: 12px;
  font-weight: 300;
  line-height: 17px;
  text-align: center;
  color: #808080;
  position: relative;
  top: 4px;
}

.disabled {
  pointer-events: none;
  color: #a1a1a1;
}

.overlay {
  position: absolute;
  width: 100%;
  height: 450px;
  background-color: #45454587;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  gap: 15px;
  font-size: 14px;
  font-weight: 300;
  line-height: 16px;
  padding: 0 10px;
}

.check-termo {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  .checkbox {
    margin: 5px 5px 0 0;
  }

  .texto-check {
    font-weight: 400;
  }
}

.botoes-box {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 10px;

  .botao-termos {
    border: none;
    border-radius: 5px;
    padding: 5px 10px;
    height: 35px;
    width: 120px;

    &.prosseguir {
      background-color: #007bff;
      color: white;
    }

    &.declinar {
      background-color: white;
      color: black;
    }

    &.disabled {
      pointer-events: none;
      opacity: 0.6;
    }
  }
}

.entrada-chat::placeholder {
  color: #303030;
  font-size: 16px;
  opacity: 1;
  line-height: 17px;
}

.corpo-chat::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.corpo-chat::-webkit-scrollbar-thumb {
  background-color: #aaaaaa;
  border-radius: 10px;
}

.corpo-chat::-webkit-scrollbar-track {
  background: #dadada;
}

.entrada-chat::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.entrada-chat::-webkit-scrollbar-thumb {
  background-color: #aaaaaa;
  border-radius: 10px;
}

.entrada-chat::-webkit-scrollbar-track {
  background: white;
}
</style>
