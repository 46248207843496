var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"icone-chat",class:{ digitando: _vm.carregandoResposta },on:{"click":function($event){return _vm.alternarChat(_vm.chatAberto)}}},[_c('img',{ref:"iconeRobo",staticClass:"icone-robo",attrs:{"src":_vm.logoIAPadraoBranco,"alt":"icone assistente outbox"}})]),_c('transition',{attrs:{"name":"chat"},on:{"before-enter":_vm.beforeEnter,"enter":_vm.enter,"leave":_vm.leave}},[(_vm.chatAberto)?_c('div',{ref:"chatWindow",staticClass:"janela-chat"},[_c('div',{staticClass:"titulo-chat"},[_c('div',{staticClass:"titulo-box"},[_c('span',{staticClass:"fundo-logo-ia"},_vm._l((_vm.imagens),function(imagem){return _c('img',{key:imagem.key,staticClass:"logo-ia",class:{
                ativo: _vm.imagemAtual === imagem.key,
                'robo-gif': !imagem.key.includes('Padrao'),
              },attrs:{"src":imagem.src}})}),0),_c('span',{ref:"chatWindowDrag",staticClass:"titulo",on:{"mousedown":_vm.startDrag,"mousemove":_vm.whileDragging,"mouseup":_vm.stopDrag,"mouseleave":_vm.stopDrag}},[_c('span',{staticClass:"texto-nome"},[_vm._v(" Singú ")]),_c('span',{staticClass:"texto-desc"},[_vm._v(" I.A. → Assistente Pessoal ")])])]),_c('span',{staticClass:"icones-box"},[_c('span',{staticClass:"material-symbols-outlined icone-info",attrs:{"id":"icone-info"}},[_vm._v(" chat_info ")]),_c('b-tooltip',{staticClass:"tooltipResults",attrs:{"target":"icone-info","triggers":"hover","placement":"top","variant":"secondary","boundary":"window"}},[_vm._v(" IA Outbox: Assistente pessoal para consultas "),_c('br'),_vm._v(" (versão Beta) ")]),_c('span',{staticClass:"material-symbols-outlined icone-fechar",on:{"click":function($event){_vm.chatAberto = false}}},[_vm._v(" close ")])],1)]),_c('div',{ref:"chatContainer",staticClass:"corpo-chat",on:{"scroll":_vm.monitoraScrollUsuario,"wheel":_vm.monitoraScrollUsuario}},[_vm._l((_vm.historicoChatSuperbox),function(mensagem,indice){return _c('div',{key:indice,staticStyle:{"display":"flex"},style:({
            'justify-content':
              mensagem.enviado === true
                ? 'flex-end'
                : mensagem.enviado === false
                ? 'flex-start'
                : 'center',
          })},[(mensagem.divisor)?_c('div',{staticStyle:{"display":"flex","width":"100%","justify-content":"center"}},[(mensagem.divisor)?_c('div',{staticClass:"mensagem-divisor",domProps:{"innerHTML":_vm._s(mensagem.texto)}}):_vm._e()]):_c('div',{class:{
              'mensagem-chat': true,
              enviado: mensagem.enviado,
              recebido: !mensagem.enviado,
            }},[_c('div',{domProps:{"innerHTML":_vm._s(mensagem.texto)}}),_c('div',{staticClass:"hora-msg"},[_vm._v(_vm._s(mensagem.hora))])])])}),(!_vm.termosUsoAssistente)?[_c('div',{staticClass:"overlay"},[_c('div',{staticClass:"texto-termo"},[_vm._v(" Usuário concorda com os "),_c('a',{attrs:{"href":_vm.linkTermos,"target":"_blank"}},[_c('strong',[_vm._v(" Termos de Uso ")])]),_vm._v(" do nosso serviço de chat, incluindo o uso de seus dados para melhorar a experiência de atendimento e a conformidade com nossas políticas de privacidade. ")]),_c('div',{staticClass:"check-termo"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.checkTermosUsoAssistente),expression:"checkTermosUsoAssistente"}],staticClass:"checkbox",attrs:{"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.checkTermosUsoAssistente)?_vm._i(_vm.checkTermosUsoAssistente,null)>-1:(_vm.checkTermosUsoAssistente)},on:{"change":function($event){var $$a=_vm.checkTermosUsoAssistente,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.checkTermosUsoAssistente=$$a.concat([$$v]))}else{$$i>-1&&(_vm.checkTermosUsoAssistente=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.checkTermosUsoAssistente=$$c}}}}),_c('span',{staticClass:"texto-check"},[_vm._v("Estou de acordo")])]),_c('div',{staticClass:"botoes-box"},[_c('button',{staticClass:"botao-termos declinar",on:{"click":function($event){_vm.chatAberto = false}}},[_vm._v(" Declinar ")]),_c('button',{staticClass:"botao-termos prosseguir",class:{
                  disabled: !_vm.checkTermosUsoAssistente,
                },on:{"click":function($event){return _vm.prosseguirChat()}}},[_vm._v(" Prosseguir ")])])])]:_vm._e()],2),[_c('div',{staticClass:"chat-container"},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.entradaChat),expression:"entradaChat"}],staticClass:"entrada-chat",attrs:{"placeholder":"Mensagem...","maxlength":"300"},domProps:{"value":(_vm.entradaChat)},on:{"keydown":_vm.verificarEnvio,"input":[function($event){if($event.target.composing)return;_vm.entradaChat=$event.target.value},_vm.checkTransicao]}}),_c('div',{staticClass:"contador"},[_vm._v(_vm._s(_vm.entradaChat.length)+"/300")]),(!_vm.carregandoResposta)?_c('span',{staticClass:"material-symbols-outlined botao-enviar",class:{
              disabled: _vm.entradaChat == '' || _vm.carregandoResposta || !_vm.termosUsoAssistente,
            },on:{"click":function($event){return _vm.enviarChat()}}},[_vm._v(" send ")]):_c('span',{staticClass:"material-symbols-outlined botao-enviar",on:{"click":function($event){return _vm.stopSimulacao()}}},[_vm._v(" stop_circle ")]),_c('div',{staticClass:"chat-aviso"},[_vm._v(" Singú está sujeito a falhas. Sempre confira as informações. ")])])]],2):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }